import type { ContactFlowOriginPage } from "~~/model/analytics.model";

export type GrowthContact = {
  origin?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  job_title?: string;
  phone_number?: string;
  interests?: string[];
  newsletter_subscribed?: boolean;
  additional_information?: string;

  website_contact_form?: {
    submitted?: boolean;
    referrer?: string;
    origin?: ContactFlowOriginPage;
    origin_page_id?: string;
    origin_page_action?: string;
  };
};

export const GROWTH_CONTACT_COOKIE_NAME = "growth_contact_information";

const PIIFields: (keyof GrowthContact)[] = [
  "email",
  "first_name",
  "last_name",
  "company",
  "job_title",
  "phone_number",
];

export function hasCompletePII(state: GrowthContact) {
  return PIIFields.every((key) => state[key] !== undefined);
}
